import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Content = styled(ParseContent)`
  z-index: 2;
  position: relative;

  & h2,
  h4 {
    opacity: 0.15;
  }

  & h2 {
    font-size: ${(props) => props.theme.font.size['75']};
  }

  & h4 {
    font-size: ${(props) => props.theme.font.size['40']};
  }

  @media screen and (max-width: 991px) {
    & h2,
    h4 {
      line-height: 30px;
    }

    & h2 {
      font-size: ${(props) => props.theme.font.size[50]};
    }

    & h4 {
      font-size: ${(props) => props.theme.font.size[24]};
    }
  }

  @media screen and (max-width: 460px) {
    & h2,
    h4 {
      line-height: 20px;
    }

    & h2 {
      font-size: ${(props) => props.theme.font.size[32]};
    }

    & h4 {
      font-size: ${(props) => props.theme.font.size[32]};
    }
  }
`

const StyledAbout = styled.div`
  max-width: 1440px;

  @media screen and (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledImage = styled(Plaatjie)`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 2;

  @media screen and (max-width: 991px) {
    opacity: 0.5;
  }

  @media screen and (max-width: 768px) {
    opacity: 0.3;
  }
`

const Gradient = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  width: 539px;
  height: 542px;
  right: -50px;
  top: 70px;
  z-index: 1;

  @media screen and (max-width: 991px) {
    display: none;
  }
`

interface AboutProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_About
}

const About: React.FC<AboutProps> = ({ fields }) => (
  <StyledAbout className="py-lg-5 position-relative">
    <div className="container py-lg-5 py-4">
      <div className="row">
        <div className="col-lg-6">
          <Content content={fields.description} />
        </div>
      </div>
    </div>
    <ImageWrapper>
      <Gradient />
      <StyledImage image={fields?.image} alt="" />
    </ImageWrapper>
  </StyledAbout>
)

export default About
